import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'

import {
  StyledOrderDetailCross,
  LoaderWrapper,
  BonusButton,
} from './order-detail.component.styles'
import { OrderHeading } from './order-heading/order-heading.component'
import OrderSharing from './order-sharing/order-sharing.component'
import { ShippingInfo } from './shipping-info/shipping-info.component'
import { RouteredOrderActions } from './order-actions/order-actions.component'
import { OrderStatus } from './order-status/order-status.component'
import { DriverInfo } from './driver-info/driver-info.component'
import { DriverRating } from './driver-rating/driver-rating.component'
import { VAN_BOOKING_ORDER_STATUS } from '../order-management.constants'

import { BonusModal } from './bonus-modal/bonus-modal.component'

import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { getCountry } from 'ggx-service/locale/country.service'

import { Heading } from 'ggx-componentlibrary/design/typography/typography.styles'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

import { getLocale } from 'ggx-src/legacy/utils/locale'
import { LoadingSpinner } from 'ggx-src/legacy/views/helpers/loading/spinner'

import { payByMonthlySettlement } from '../../van-booking/duck/selectors'
import { orderService } from '../../van-booking/api/order-api.service'

import { apiService as orderRequestService } from '../api/api.service'
import { priceQuotationService } from 'ggx-service/api/price-quotation.service'

const whatsappUrlFormatter = ({ t, order, traceURL }) => {
  const startUrl = 'https://wa.me/?text='
  if (!order?.waypoints) {
    return startUrl
  }
  const pickupAddress = order.waypoints[0]?.address
  const dropoffAddress = order.waypoints[order.waypoints.length - 1]?.address
  const defaultContent = t('share_content__transport__before', {
    orderId: order.id,
    startAddr: pickupAddress,
    endAddr: dropoffAddress,
    tntLink: traceURL,
  })

  switch (order.status) {
    case 'active':
      return (
        startUrl +
        encodeURIComponent(
          t('share_content__transport__during', {
            orderId: order.id,
            startAddr: pickupAddress,
            endAddr: dropoffAddress,
            driverName: order.order?.driver_nickname || '',
            contactNo: order.order?.driver_phone_number || '',
            licensePlate: order.order?.driver_license_plate || '',
            tntLink: traceURL,
          })
        )
      )
    case 'completed':
      return (
        startUrl +
        encodeURIComponent(
          t('share_content__transport__after', {
            orderId: order.id,
            startAddr: pickupAddress,
            endAddr: dropoffAddress,
            driverName: order.order?.driver_nickname || '',
            tntLink: traceURL,
          })
        )
      )
    default:
      return startUrl + encodeURIComponent(defaultContent)
  }
}

const OrderDetail = ({
  onRequestClose = () => {},
  router,
  orderContactInfo = {},
}) => {
  const [t] = useTranslation()
  const [showOrderDetail, setShowOrderDetail] = useState(false)
  const [showBonusModal, setShowBonusModal] = useState(false)
  const [order, setOrder] = useState({})
  const [breakdownResponse, setBreakdownResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const selectedOrderId = router?.params?.id
  const inProgressOrder =
    order?.status === 'active' || order?.status === 'pending'

  const featureFlagBonus = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'transport_tips' })
  )
  const isMonthlySettlement = useSelector(state =>
    payByMonthlySettlement(state)
  )
  const featureFlagGGBWebTransportNid = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'ggb_web_transport_nid' })
  )

  const notifications = useSelector(state => state.notification?.messages)
  const notificationsPreviousCount = useRef(notifications.count)

  let locale = getLocale()
  if (locale === 'en-hk' || locale === 'en-sg') {
    locale = 'en'
  } else if (locale === 'vi-vn') {
    locale = 'vi'
  }
  const country = getCountry()
  const traceURL = `${
    process.env.TRACK_AND_TRACE_URL
  }/${locale}/${country.toLowerCase()}/transport/${order.id}`

  const fetchOrderId = useCallback(async ({ id } = {}) => {
    setLoading(true)
    setShowOrderDetail(true)

    priceQuotationService
      .getBreakdown(id)
      .then(setBreakdownResponse)
      .catch(console.error)

    try {
      const { id: oldId, nid, ...result } = await orderService.getOrder(id)
      setOrder({
        ...result,
        id: featureFlagGGBWebTransportNid ? nid || oldId : oldId,
      })
    } catch (error) {
      setShowOrderDetail(false)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (selectedOrderId !== undefined) {
      fetchOrderId({ id: selectedOrderId })
    }
  }, [selectedOrderId, fetchOrderId])

  useEffect(() => {
    const timerInterval = 120000 // 2 minutes
    const intervalId = setInterval(() => {
      if (selectedOrderId && showOrderDetail && inProgressOrder) {
        fetchOrderId({ id: selectedOrderId })
      }
    }, timerInterval)
    return () => clearInterval(intervalId)
  }, [showOrderDetail, selectedOrderId, fetchOrderId, inProgressOrder])

  useEffect(() => {
    const lastNotificationOrderId = notifications?.slice(-1)[0]?.orderRequestId
    const lastNotificationOrderIdIsSelected =
      order?.id === lastNotificationOrderId

    if (
      selectedOrderId !== undefined &&
      notifications.length > notificationsPreviousCount.current &&
      lastNotificationOrderIdIsSelected
    ) {
      fetchOrderId({ id: selectedOrderId })
    }

    notificationsPreviousCount.current = notifications.length
  }, [notifications, fetchOrderId, selectedOrderId, order])

  const handleCloseOrderDetail = () => {
    setShowOrderDetail(false)
    onRequestClose()
  }

  const bonusButtonText =
    !order.bonus || order.bonus === 0
      ? t('button__ggv__add_bonus')
      : `${t('button__ggv__edit_bonus')} ${formatCurrency({
          amount: order.bonus,
        })}`

  const handleCopyLink = () => {
    copy(traceURL)
    displayAlert({ message: t('text__tracking_link_copied_to_clipboard') })
  }

  const whatsappURL = useMemo(() => {
    return whatsappUrlFormatter({ t, order, traceURL })
  }, [order.id, order.status])

  return (
    <Drawer
      open={showOrderDetail}
      anchor="right"
      variant="persistent"
      data-testid="order-detail-drawer"
    >
      <StyledOrderDetailCross onClick={handleCloseOrderDetail} />
      {loading || order === undefined ? (
        <LoaderWrapper>
          <LoadingSpinner />
        </LoaderWrapper>
      ) : (
        <>
          <OrderHeading
            orderId={order.id}
            vehicle={order.vehicle}
            rating={order.order?.driver_rating}
          />
          {order.status === VAN_BOOKING_ORDER_STATUS.COMPLETED &&
            !order?.order?.driver_rating && (
              <DriverRating
                orderId={order.id}
                name={order?.order?.driver_nickname}
                onRatingSuccess={() => fetchOrderId({ id: selectedOrderId })}
              />
            )}
          {order.status === VAN_BOOKING_ORDER_STATUS.PENDING &&
            featureFlagBonus &&
            !isMonthlySettlement &&
            getCountry() !== 'TW' && (
              <>
                <Heading typographyStyles="mb-s">
                  {t('text__cta__bonus_driver')}
                </Heading>
                <BonusButton onClick={() => setShowBonusModal(true)}>
                  {bonusButtonText}
                </BonusButton>
              </>
            )}
          <DriverInfo order={order} />
          <OrderStatus order={order} />
          <OrderSharing
            uuid={order.uuid}
            status={order.status}
            whatsappURL={whatsappURL}
            traceURL={traceURL}
            handleCopyLink={handleCopyLink}
            sendOrderReceipt={orderRequestService.sendOrderReceipt}
          />
          <ShippingInfo
            order={order}
            orderContactInfo={orderContactInfo}
            breakdownResponse={breakdownResponse}
          />
          <RouteredOrderActions
            orderId={order.id}
            orderUuid={order.uuid}
            orderContactPhoneNumber={order.phone_number}
            waypoints={order.waypoints}
            status={order.status}
            onOrderCancelled={fetchOrderId}
            breakdown={breakdownResponse?.breakdown}
          />
          {showBonusModal && (
            <BonusModal
              onModalClose={() => setShowBonusModal(false)}
              orderId={order.id}
              initialValue={parseInt(order?.bonus, 10) || 0}
              onBonusAdded={() => fetchOrderId({ id: selectedOrderId })}
              vehicle={order.vehicle}
            />
          )}
        </>
      )}
    </Drawer>
  )
}

OrderDetail.propTypes = {
  onRequestClose: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

const RouteredOrderDetail = withRouter(OrderDetail)

export { OrderDetail, RouteredOrderDetail }
