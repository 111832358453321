import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formValueSelector } from 'redux-form'

import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import { VoucherModal } from 'ggx-src/billing/voucher/voucher-modal.component'

import { StyledLink, Menu } from './price-breakdown.component.styles'

import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

const CouponSelection = ({ input, total, discount }) => {
  const [t] = useTranslation()
  const reduxFormSelector = formValueSelector('vanbooking-order')
  const vehicleType = useSelector(state => reduxFormSelector(state, 'vehicle'))
  const [showVoucherModal, setShowVoucherModal] = useState(false)

  const [hasChanged, setHasChanged] = useState(false)
  useEffect(() => {
    if (typeof discount === 'number' && hasChanged) {
      displayAlert({
        message: t('feedback__coupon_applied', {
          discount: formatCurrency({ amount: Math.abs(discount) }),
        }),
        autoClose: true,
      })
      setHasChanged(false)
    }
  }, [discount])

  return discount !== undefined ? (
    <StyledLink as="a" noMargin>
      <span>{formatCurrency({ amount: discount })}</span>
      <Menu>
        <Menu.Row noMargin onClick={() => input.onChange([])}>
          {t('remove_coupon')}
        </Menu.Row>
      </Menu>
    </StyledLink>
  ) : (
    <StyledLink as="a" noMargin>
      <span onClick={() => setShowVoucherModal(true)}>{t('add_coupon')}</span>
      {showVoucherModal && (
        <VoucherModal
          orderPrice={total}
          serviceType="transport"
          vehicleType={vehicleType}
          onSelectCoupon={coupon => {
            input.onChange([coupon.available[0].toString()])
            setHasChanged(true)
          }}
          onModalClose={() => setShowVoucherModal(false)}
        />
      )}
    </StyledLink>
  )
}

CouponSelection.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func,
  }),
  total: PropTypes.number,
  discount: PropTypes.number,
}

export { CouponSelection }
