import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formValueSelector, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'

import { translate } from 'ggx-service/i18n/translate.service'
import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import { getCountry } from 'ggx-service/locale/country.service'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import {
  ItemWrapper,
  AdditionalServiceItem,
  StyledTooltip,
  StyledTooltipText,
  AdditionalServiceItemWithIcon,
  StyledTooltipLink,
  StyledTooltipIcon,
} from './price-breakdown.component.styles'
import { CouponSelection } from './coupon-selection.component.js'
import { getLocale } from 'ggx-src/legacy/utils/locale'
import { useFeatureFlagValue } from 'ggx-global/hooks/use-feature-flag'

export const getCargoCompensationItemPostfix = (t, titleFromCCItem) => {
  const tier = Number(titleFromCCItem.substr(4))
  switch (tier) {
    case 10:
    case 20:
    case 30:
    case 40:
      return ` - ${t('comp__tier0')}`
    case 11:
      return ` - ${t('comp__tier1')}`
    case 12:
    case 22:
    case 32:
      return ` - ${t('comp__tier2')}`
    case 13:
    case 23:
    case 33:
    case 43:
      return ` - ${t('comp__tier3')}`
    default:
      return ''
  }
}

const AdditionalServiceCharge = ({ title, value, typographyStyles }) => (
  <ItemWrapper>
    <AdditionalServiceItem typographyStyles={typographyStyles}>
      {title}
    </AdditionalServiceItem>
    <AdditionalServiceItem typographyStyles={typographyStyles}>
      {value}
    </AdditionalServiceItem>
  </ItemWrapper>
)

AdditionalServiceCharge.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  typographyStyles: PropTypes.string,
}

const standardAdditionalServiceKeys = [
  'compartment_vehicle',
  'borrow_carts',
  'borrow_forklift_pcs',
  'remove_net',
  'pet',
  'need_pet',
  'new_car',
  'speak_english',
  'carry',
  'free_trade_zone_delivery',
  'front_seat_passenger',
  'dumper_construction_waste',
  'return_trip',
  'door_to_door_delivery',
  'proxy_payment',
  'movers',
  'moving_ground_floor',
  'moving_ground_floor_both_ways',
  'moving_up_or_down_stairs',
  'moving_up_and_down_stairs',
  'assistants',
  'night_charge',
  'need_covered_truck',
  'need_tailgate',
  'need_delivery_box',
  'need_refrigeration',
  'need_carry',
  'night_extra_charge',
  'midnight_extra_charge',
  'holiday_extra_charge',
  'same_region_charge',
  'premium_service',
  'voucher_code_discount',
  'voucher_code',
  'epod',
  'cargo_insurance_level',
  'multi_point_concession',
  'need_premium_service',
]

const PriceBreakdown = ({ quotation = { breakdown: {} } }) => {
  const [t] = useTranslation()

  const { breakdown, total } = quotation

  const { fee, ...additionalService } = breakdown
  const displayedRouteFee = formatCurrency({ amount: fee?.value })
  const customAdditionalServiceKeys = Object.keys(additionalService).filter(
    key => !standardAdditionalServiceKeys.includes(key)
  )

  const reduxFormSelector = formValueSelector('vanbooking-order')
  const passengerCount = useSelector(state =>
    reduxFormSelector(state, 'order_request_detail_attributes.passenger_count')
  )
  const cartCount = useSelector(state =>
    reduxFormSelector(state, 'order_request_detail_attributes.cart_count')
  )
  const vehicleType = useSelector(state => reduxFormSelector(state, 'vehicle'))
  const hasEpod = useSelector(state =>
    reduxFormSelector(state, 'order_request_detail_attributes.epod')
  )

  const isSG = getCountry() === 'SG'
  const moveGoodsSummaryTitle =
    getCountry() === 'TW' && vehicleType === 'van'
      ? t('newOrder.tab.additional.selection.doorToDoorDelivery.title')
      : translate('newOrder.tab.summary.breakdown.moveGoods')

  const getMoveGoodsSummaryValue = value =>
    getCountry() === 'TW'
      ? formatCurrency({ amount: value })
      : translate('newOrder.tab.summary.title.estimatedTotal.price.quote')

  const featureFlagEnableCouponFeature = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'enable_coupon_feature' })
  )

  const featureFlagCargoCompensation = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'cargo_compensation' })
  )

  return (
    <>
      <AdditionalServiceCharge
        title={t('common__price_breakdown__base_fee')}
        value={displayedRouteFee}
        typographyStyles="c-black"
      />

      {featureFlagEnableCouponFeature && (
        <ItemWrapper>
          <AdditionalServiceItem typographyStyles="c-black">
            {t('coupon')}
          </AdditionalServiceItem>
          <Field
            name="user_voucher_ids"
            defaultValue={null}
            component={CouponSelection}
            total={total}
            discount={
              additionalService.voucher_code_discount?.value ||
              additionalService.voucher_code?.value
            }
          />
        </ItemWrapper>
      )}

      {passengerCount && (
        <AdditionalServiceCharge
          title={translate('newOrder.tab.summary.breakdown.passengers').replace(
            '%',
            passengerCount
          )}
          value={formatCurrency({
            amount: additionalService.compartment_vehicle
              ? additionalService.compartment_vehicle.value
              : 0,
          })}
        />
      )}

      {featureFlagCargoCompensation && additionalService.cargo_insurance_level && (
        <ItemWrapper>
          <AdditionalServiceItemWithIcon>
            {t('orderDetails.insurance.insurance')}
            {getCargoCompensationItemPostfix(
              t,
              additionalService.cargo_insurance_level.title
            )}
            <StyledTooltipLink
              href="https://www.gogox.com/compensation/"
              target="_blank"
            >
              <StyledTooltipIcon />
            </StyledTooltipLink>
          </AdditionalServiceItemWithIcon>
          <AdditionalServiceItem>
            {formatCurrency({
              amount: additionalService.cargo_insurance_level?.value ?? 0,
            })}
          </AdditionalServiceItem>
        </ItemWrapper>
      )}

      {additionalService.borrow_carts && (
        <AdditionalServiceCharge
          title={translate('newOrder.tab.summary.breakdown.rentCart').replace(
            '%',
            () => {
              if (cartCount) {
                return cartCount
              }

              if (additionalService.borrow_carts.quantity) {
                return additionalService.borrow_carts.quantity
              }

              return '1'
            }
          )}
          value={formatCurrency({
            amount: additionalService.borrow_carts.value ?? 0,
          })}
        />
      )}

      {cartCount && additionalService.borrow_forklift_pcs && (
        <AdditionalServiceCharge
          title={translate(
            'newOrder.tab.summary.breakdown.rentForklift'
          ).replace('%', cartCount)}
          value={formatCurrency({
            amount: additionalService.borrow_forklift_pcs.value,
          })}
        />
      )}

      {additionalService.remove_net && (
        <AdditionalServiceCharge
          title={t('newOrder.tab.additional.selection.longGoods.summary')}
          value={formatCurrency({ amount: 20 })}
        />
      )}

      {additionalService?.remove_net?.value === 40 && (
        <AdditionalServiceCharge
          title={t('newOrder.tab.additional.selection.tallGoods.summary')}
          value={formatCurrency({ amount: 20 })}
        />
      )}

      {additionalService.pet && (
        <AdditionalServiceCharge
          title={translate('newOrder.tab.summary.breakdown.pet')}
          value={formatCurrency({ amount: additionalService.pet.value })}
        />
      )}

      {additionalService.need_pet && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__pets')}
          value={formatCurrency({ amount: additionalService.need_pet.value })}
        />
      )}

      {additionalService.new_car && (
        <AdditionalServiceCharge
          title={
            getCountry() !== 'HK'
              ? translate('newOrder.tab.summary.breakdown.newVehicle')
              : t('ar_premium_van')
          }
          value={formatCurrency({ amount: additionalService.new_car.value })}
        />
      )}

      {additionalService.speak_english && (
        <AdditionalServiceCharge
          title={translate('newOrder.tab.summary.breakdown.engDriver')}
          value={formatCurrency({
            amount: additionalService.speak_english.value,
          })}
        />
      )}

      {additionalService.premium_service && (
        <ItemWrapper>
          <AdditionalServiceItemWithIcon>
            {additionalService.premium_service.title}
            <StyledTooltipLink
              target="_blank"
              href={t('url__platform_support')}
            >
              <StyledTooltipIcon />
            </StyledTooltipLink>
          </AdditionalServiceItemWithIcon>
          <AdditionalServiceItem>
            {formatCurrency({
              amount: additionalService.premium_service.value,
            })}
          </AdditionalServiceItem>
        </ItemWrapper>
      )}

      {additionalService.need_premium_service &&
        additionalService.need_premium_service.value > 0 && (
          <ItemWrapper>
            <AdditionalServiceItemWithIcon>
              {t('need_premium_service')}
              <StyledTooltipLink
                target="_blank"
                href={t('url__platform_support')}
              >
                <StyledTooltipIcon />
              </StyledTooltipLink>
            </AdditionalServiceItemWithIcon>
            <AdditionalServiceItem>
              {formatCurrency({
                amount: additionalService.need_premium_service.value,
              })}
            </AdditionalServiceItem>
          </ItemWrapper>
        )}

      {additionalService.carry && (
        <AdditionalServiceCharge
          title={moveGoodsSummaryTitle}
          value={getMoveGoodsSummaryValue(additionalService.carry.value)}
        />
      )}

      {additionalService.free_trade_zone_delivery && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__restricted_area')}
          value={formatCurrency({
            amount: additionalService.free_trade_zone_delivery.value,
          })}
        />
      )}

      {additionalService.front_seat_passenger && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__front_seat_passenger')}
          value={formatCurrency({
            amount: additionalService.front_seat_passenger.value,
          })}
        />
      )}

      {additionalService.dumper_construction_waste && (
        <AdditionalServiceCharge
          title={translate('newOrder.tab.summary.breakdown.disposeWaste')}
          value={translate(
            'newOrder.tab.summary.title.estimatedTotal.price.quote'
          )}
        />
      )}

      {additionalService.return_trip && (
        <AdditionalServiceCharge
          title={t('newOrder.tab.additional.selection.returnTrip.title')}
          value={formatCurrency({
            amount: additionalService.return_trip.value,
          })}
        />
      )}

      {additionalService.door_to_door_delivery && (
        <AdditionalServiceCharge
          title={t(
            'newOrder.tab.additional.selection.doorToDoorDelivery.title'
          )}
          value={formatCurrency({
            amount: additionalService.door_to_door_delivery.value,
          })}
        />
      )}

      {additionalService.proxy_payment && (
        <AdditionalServiceCharge
          title={t('newOrder.tab.additional.selection.proxyBuying.title')}
          value={formatCurrency({
            amount: additionalService.proxy_payment.value,
          })}
        />
      )}

      {additionalService.movers && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__movers')}
          value={formatCurrency({ amount: additionalService.movers.value })}
        />
      )}

      {additionalService.moving_ground_floor && (
        <AdditionalServiceCharge
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.groundFloor'
          )}
          value={formatCurrency({
            amount: additionalService.moving_ground_floor.value,
          })}
        />
      )}

      {additionalService.moving_ground_floor_both_ways && (
        <AdditionalServiceCharge
          title={t('common__both_way_ground_floor')}
          value={formatCurrency({
            amount: additionalService.moving_ground_floor_both_ways.value,
          })}
        />
      )}

      {additionalService.moving_up_or_down_stairs && (
        <AdditionalServiceCharge
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.upstairsOrDownstairs'
          )}
          value={formatCurrency({
            amount: additionalService.moving_up_or_down_stairs.value,
          })}
        />
      )}

      {additionalService.moving_up_and_down_stairs && (
        <AdditionalServiceCharge
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.upstairsAndDownstairs'
          )}
          value={formatCurrency({
            amount: additionalService.moving_up_and_down_stairs.value,
          })}
        />
      )}

      {additionalService.assistants && (
        <AdditionalServiceCharge
          title={t(
            'newOrder.tab.additional.selection.movingHelp.options.extra'
          )}
          value={formatCurrency({ amount: additionalService.assistants.value })}
        />
      )}

      {additionalService.night_charge && (
        <AdditionalServiceCharge
          title={t('newOrder.tab.summary.breakdown.nightcharge')}
          value={formatCurrency({
            amount: additionalService.night_charge.value,
          })}
        />
      )}

      {additionalService.need_covered_truck && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__covered_lorry')}
          value={formatCurrency({
            amount: additionalService.need_covered_truck.value ?? 0,
          })}
        />
      )}

      {additionalService.need_tailgate && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__tailgate')}
          value={formatCurrency({
            amount: additionalService.need_tailgate.value ?? 0,
          })}
        />
      )}

      {additionalService.need_delivery_box && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__delivery_box')}
          value={formatCurrency({
            amount: additionalService.need_delivery_box.value ?? 0,
          })}
        />
      )}

      {additionalService.need_refrigeration && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__refrigeration')}
          value={formatCurrency({
            amount: additionalService.need_refrigeration.value,
          })}
        />
      )}

      {additionalService.need_carry && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__need_carry')}
          value={formatCurrency({
            amount: additionalService.need_carry.value ?? 0,
          })}
        />
      )}

      {additionalService.night_extra_charge && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__night_extra_charge')}
          value={formatCurrency({
            amount: additionalService.night_extra_charge.value,
          })}
        />
      )}

      {additionalService.midnight_extra_charge && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__midnight_extra_charge')}
          value={formatCurrency({
            amount: additionalService.midnight_extra_charge.value,
          })}
        />
      )}

      {additionalService.holiday_extra_charge && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__holiday_extra_charge')}
          value={formatCurrency({
            amount: additionalService.holiday_extra_charge.value,
          })}
        />
      )}

      {additionalService.same_region_charge && (
        <AdditionalServiceCharge
          title={t('order_request__additional_service__same_region_charge')}
          value={formatCurrency({
            amount: additionalService.same_region_charge.value,
          })}
        />
      )}

      {additionalService.multi_point_concession ? (
        <AdditionalServiceCharge
          title={t('multi_point_concession')}
          value={formatCurrency({
            amount: additionalService.multi_point_concession.value,
          })}
        />
      ) : null}

      {customAdditionalServiceKeys.map(serviceKey => {
        const lang = getLocale().replace(
          /-(\w{2})$/,
          (_, p1) => '_' + p1.toUpperCase()
        )
        return (
          <AdditionalServiceCharge
            title={
              additionalService[serviceKey].extras?.[lang] ||
              additionalService[serviceKey].title
            }
            value={formatCurrency({
              amount: additionalService[serviceKey].value,
            })}
          />
        )
      })}

      {isSG && hasEpod && (
        <AdditionalServiceCharge
          title={t('require_epod')}
          value={formatCurrency({
            amount: 0,
          })}
        />
      )}
    </>
  )
}

PriceBreakdown.propTypes = {
  quotation: PropTypes.object,
  passengerCount: PropTypes.string,
  cartCount: PropTypes.string,
  vehicleType: PropTypes.string,
}

export { PriceBreakdown, AdditionalServiceCharge }
