import styled from "styled-components"

import { Tooltip } from "ggx-componentlibrary/components/tooltip/tooltip.component"
import { ContentBox } from "ggx-componentlibrary/components/tooltip/tooltip.component.styles"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { NAV_SIDEBAR_WIDTH } from "../global/navigation/navigation.constants"
import { Pagination } from "ggx-componentlibrary/components/table/pagination/pagination.component"
import { PaginationText } from "ggx-componentlibrary/components/table/pagination/pagination.component.styles"
import {
  BodyBold,
  HeadingXL,
} from "ggx-componentlibrary/design/typography/typography.styles"
import { Dropdown } from "ggx-componentlibrary/components/dropdown/dropdown.component"
import {
  StyledControl,
  StyledDropdownIndicator,
  StyledPlaceholder,
} from "ggx-componentlibrary/components/dropdown/dropdown.component.styles"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { Page } from 'ggx-global/page/page.component'

const DownArrow = styled.span`
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 8px solid ${COLOR.GREY_DARK};
  margin-left: ${SPACING.S};
  cursor: pointer;
`

const UpArrow = styled.span`
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 8px solid ${COLOR.GREY_DARK};
  margin-left: ${SPACING.S};
  cursor: pointer;
`

const PAGE_COMPONENT_PADDING = SPACING.L
const FOOTER_HEIGHT = "71px"

const FilterContainer = styled.div`
  display: flex;
`

const FilterAndSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${SPACING.XL};
`

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  width: calc(100% + ${SPACING} + ${SPACING.L});
  padding-bottom: ${SPACING.S};
  background-color: white;
  height: ${FOOTER_HEIGHT};
  left: 0;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -${SPACING.L};
  padding: 0 25.42px 0 24.4px;
`

const StyledPagination = styled(Pagination)`
  margin-top: 0;
  flex-grow: 1;
  ${PaginationText} {
    margin: 0;
  }
`

const TotalResults = styled(BodyBold)`
  margin-right: ${SPACING.L};
  margin-bottom: 0;
`

const TotalPrice = styled(HeadingXL)`
  margin-bottom: 4px;
`

const NoDataMessageContainer = styled.div`
  margin-top: -${FOOTER_HEIGHT};
`

const StyledTooltip = styled(Tooltip)`
  margin-bottom: 0;
  margin-right: ${SPACING.XXS};
  position: relative;
  top: -1px;
  ${ContentBox} {
    transform: translateX(-70px);
  }
`

const DownloadReportDropdown = styled(Dropdown)`
  width: 182px;

  ${StyledControl} {
    background-color: ${COLOR.BLUE_MEDIUM};
  }

  ${StyledPlaceholder} {
    color: ${COLOR.WHITE};
  }

  ${StyledDropdownIndicator} {
    use {
      fill: ${COLOR.WHITE};
    }
  }
`

const StyledPage = styled(Page)`
  position: relative;
  height: 100vh;
`

export {
  FilterContainer,
  Footer,
  StyledPagination,
  TotalResults,
  TotalPrice,
  NoDataMessageContainer,
  FOOTER_HEIGHT,
  FilterAndSearchContainer,
  StyledTooltip,
  DownloadReportDropdown,
  DownArrow,
  UpArrow,
  StyledPage,
}
