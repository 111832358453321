import styled, { keyframes } from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import {
  FONT_SIZE,
  FONT_WEIGHT,
} from "ggx-componentlibrary/design/typography/typography.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"

import { Modal } from "ggx-componentlibrary/components/modal/modal.component"
import {
  Content,
  Footer,
} from "ggx-componentlibrary/components/modal/modal.component.styles"
import {
  RadioButtonGroup,
  RadioButton,
} from "ggx-componentlibrary/components/radio-button/radio-button.component"
import { RadioButtonWrapper } from "ggx-componentlibrary/components/radio-button/radio-button.component.styles"
import {
  Body,
  SmallText,
} from "ggx-componentlibrary/design/typography/typography.styles"
import { ServiceCard } from "../service-card/service.card.component"

import SmallPackageIcon from "./assets/package-below-60.svg"
import LargePackgeIcon from "./assets/package-over-60.svg"

const buttonStyle = {
  width: "168px",
  height: SPACING.XL5,
  padding: SPACING.S,
}

const StyledModal = styled(Modal)`
  transition: width 0.15s;
  ${Content} {
    margin-top: 0;
    padding: 0;
    overflow: hidden;
  }

  ${Footer} {
    justify-content: space-between;
    align-items: center;
    padding-left: ${SPACING.L};
  }
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const FadeInWrapper = styled.div`
  animation: ${fadeIn} 300ms;
  margin-bottom: ${SPACING.M};
`

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  margin-bottom: ${SPACING.M};

  ${RadioButtonWrapper} {
    display: inline-block;
    margin: 0 ${SPACING.XS} 0 0;
    &[aria-checked="true"] ${RadioButton} {
      ${Body} {
        color: ${(props) =>
          props.disabled ? COLOR.GREY_DARK : COLOR.BLUE_MEDIUM};
        border-color: ${(props) =>
          props.disabled ? COLOR.GREY_LIGHT : COLOR.BLUE_MEDIUM};
        font-weight: ${FONT_WEIGHT.BOLD};
      }
    }
  }

  ${RadioButton} {
    font-size: ${FONT_SIZE.MEDIUM};
    ${(props) => props?.buttonStyle && props.buttonStyle};
  }
`

const StyledSmallPackageIcon = styled(SmallPackageIcon)`
  width: 56px;
  height: 56px;
`

const StyledLargePackgeIcon = styled(LargePackgeIcon)`
  width: 66px;
  height: 56px;
`

const Container = styled.div`
  display: flex;
`

const QuestionsContainer = styled.div`
  width: 444px;
  padding: ${SPACING.XL2} ${SPACING.L};
  flex-shrink: 0;
`

const ServiceCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  background-color: ${COLOR.GREY_LIGHTEST};
  padding: ${SPACING.XL2} ${SPACING.L};
  animation: ${fadeIn} 1s;
`

const StyledServiceCard = styled(ServiceCard)`
  margin-right: ${SPACING.S};
`

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const QuestionCount = styled(SmallText)`
  flex-grow: 1;
  margin-bottom: 0;
`

export {
  StyledModal,
  FadeInWrapper,
  StyledRadioButtonGroup,
  StyledSmallPackageIcon,
  StyledLargePackgeIcon,
  buttonStyle,
  Container,
  QuestionsContainer,
  ServiceCardsContainer,
  StyledServiceCard,
  FooterContainer,
  QuestionCount,
}
